import React from 'react';

import { FlashMessage, FlashMessageOptions, ShowFlashMessage } from './types';

const flashMessageContext = React.createContext<{
  showFlashMessage: ShowFlashMessage;
}>({
  showFlashMessage: (
    message: string,
    options: FlashMessageOptions,
  ): FlashMessage => ({
    id: '1',
    message,
    options,
  }),
});

export default flashMessageContext;
