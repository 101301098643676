import React, { PureComponent } from 'react';

import Header from './Header';
import NoHeader from './NoHeader';
import { PageHeaderProps, PageHeaderState } from './types';

class PageHeader extends PureComponent<PageHeaderProps, PageHeaderState> {
  public state = { scroll: 0 };

  public componentDidMount(): void {
    if (!this.props.withScroll) return;
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  }

  public componentWillUnmount(): void {
    if (!this.props.withScroll) return;
    window.removeEventListener('scroll', this.handleScroll);
  }

  public getScrollTop = (): number =>
    process.browser && document.documentElement && document.body
      ? document.documentElement.scrollTop || document.body.scrollTop
      : 0;

  public handleScroll = (): void => {
    this.setState({ scroll: this.getScrollTop() });
  };

  public render(): JSX.Element {
    return this.props.noHeader ? (
      <NoHeader />
    ) : (
      <Header
        withScroll={this.props.withScroll}
        scroll={this.state.scroll}
        hideMenu={this.props.hideMenu}
      />
    );
  }
}

export default PageHeader;
