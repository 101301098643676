import styled from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

export default styled.div`
  ${media.moreThan('md')`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  `};
`;
