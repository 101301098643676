import styled from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

export default styled.div<{ css?: string }>`
  width: 90%;
  margin: 0 5%;
  padding: 0;
  text-align: center;
  position: relative;

  ${media.moreThan('lg')`
    width: 1200px;
    margin: 0 auto;
  `};

  ${props => props.css || ''};
`;
