import createReduxStore from 'lib/createReduxStore';
import { State, Store } from 'types';

let clientReduxStore: Store | null | undefined = null;
const getReduxStore = (serverState: State = {}): Store => {
  if (!process.browser) {
    return createReduxStore(serverState);
  }

  const state = clientReduxStore ? clientReduxStore.getState() : serverState;
  clientReduxStore = createReduxStore(state);

  return clientReduxStore;
};

export default getReduxStore;
