import styled from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

const navigationItemMargin = '30px';

export const navigationItemStyle = `
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export default styled.li`
  margin-top: 15px;

  ${media.moreThan('sm')`
    display: inline-block;
    margin-top: 0;
    margin-left: ${navigationItemMargin};

    &:last-child {
      margin-right: ${navigationItemMargin};
    }
  `};

  a,
  div {
    cursor: pointer;
    display: block;
    ${navigationItemStyle};
  }
`;
