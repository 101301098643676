import createReactContext from 'create-react-context';
import { NextRouter } from 'next/router';

const UrlContext = createReactContext<NextRouter>(undefined as NextRouter);
const { Consumer, Provider } = UrlContext;

Consumer.displayName = 'UrlConsumer';
Provider.displayName = 'UrlProvider';

export { Provider };
export default Consumer;
