import styled from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

export default styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;

  ${media.moreThan('md')`
    position: relative;
  `};
`;
