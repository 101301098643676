import { merge } from 'lodash';
import { INTEGRATION_INDEX } from '@sizeid/shared/constants/routes';

import mutableDefaultTheme from './default';
import { DefaultThemeType } from './types';

const defaultTheme = merge({}, mutableDefaultTheme);

const integrationTheme: DefaultThemeType = merge(defaultTheme, {
  backgrounds: {
    header: defaultTheme.colors.black.light[400],
    graySection: defaultTheme.colors.black.light[50],
    grayWave: 'sidbWave',
  },
  navigation: {
    type: 'integration',
    anchorMargin: 'top',
    flexDirection: 'row',
  },
  logo: {
    image: 'sizeid-integration',
    width: {
      normal: 'auto',
      big: 'auto',
    },

    href: {
      page: INTEGRATION_INDEX.page,
      route: INTEGRATION_INDEX.route,
    },
  },
});

export default integrationTheme;
