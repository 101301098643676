import styled, {
  css,
  FlattenInterpolation,
  ThemedStyledProps,
  StyledComponent,
} from 'styled-components';
import { key } from 'styled-theme';
import { prop } from 'styled-tools';
import { media } from '@sizeid/commons/utils/styles';
import { capitalize } from '@sizeid/commons/utils/strings';

export const headingStyle = (
  tag: string,
): FlattenInterpolation<ThemedStyledProps<unknown, unknown>> => css`
  color: ${key('text.headings.common.color')};
  font-weight: ${key('text.headings.common.fontWeight')};

  font-size: ${key(`text.headings.${tag}.fontSize`)};
  line-height: ${key(`text.headings.${tag}.lineHeight`)};

  ${media.lessThan('sm')`
    font-size: ${key(`text.headings.${tag}.mobileFontSize`)};
  `};
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const makeHeading = (tag: string): StyledComponent<any, any, any, any> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const Heading = styled(tag)`
    ${headingStyle(tag)};
    ${prop('css', '')};
  `;

  Heading.displayName = capitalize(tag);

  return Heading;
};

export default makeHeading;
