import { gql } from '@apollo/client';

const createSessionMutation = gql`
  mutation createSessionMutation {
    createSession {
      id
      expires
      secure
      httpOnly
    }
  }
`;

export default createSessionMutation;
