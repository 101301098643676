import { applyMiddleware, compose, createStore } from 'redux';

import { Reducers, State, Store, Options } from 'types';

import createReduxMiddleware from './createReduxMiddleware';
import createReduxReducer from './createReduxReducer';

const makeReducersHotReloadable = (
  store: Store,
  platformReducers: Reducers,
): void => {
  if (!module.hot || typeof module.hot.accept !== 'function') return;

  module.hot.accept('./createReduxReducer', () => {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const createReduxReducer = require('./createReduxReducer').default;
    store.replaceReducer(createReduxReducer(platformReducers));
  });
};

const createReduxStore = (
  initialState: State,
  options: Options = {},
): Store => {
  const { platformMiddleware = [], platformReducers = {} } = options;
  const middleware = createReduxMiddleware(platformMiddleware);
  const reducer = createReduxReducer(platformReducers);
  let storeEnahncer = applyMiddleware(...middleware);

  if (
    process.env.NODE_ENV !== 'production' &&
    process.browser &&
    window.__REDUX_DEVTOOLS_EXTENSION__
  ) {
    storeEnahncer = compose(
      storeEnahncer,
      window.__REDUX_DEVTOOLS_EXTENSION__(),
    );
  }

  const store = createStore(reducer, initialState, storeEnahncer);
  makeReducersHotReloadable(store, platformReducers);

  return store;
};

export default createReduxStore;
