import { combineReducers, Reducer as ReduxReducer } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { RESET_REDUX_STORE } from 'constants/redux';
import { Action, State, Reducers } from 'types';

const createReduxReducer = (
  platformReducers: Reducers,
): ReduxReducer<State, Action> => {
  const rootReducer = combineReducers<State>({
    ...platformReducers,
    form: formReducer,
  });

  return (state: State | undefined, action: Action) => {
    if (action.type === RESET_REDUX_STORE) {
      state = {
        form: {},
      };
    }

    return rootReducer(state, action);
  };
};

export default createReduxReducer;
