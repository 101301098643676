import styled from 'styled-components';
import { key } from 'styled-theme';

export default styled.div`
  background: ${key('colors.black.light.400')};

  &,
  a,
  a:hover {
    color: ${key('colors.black.light.200')};
  }

  svg {
    fill: currentColor;
  }
`;
