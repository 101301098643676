import React from 'react';
import SocialIcon from '@sizeid/commons/components/SocialIcon';
import { SocialIconImage } from '@sizeid/commons/components/SocialIcon/types';

import { withTranslation } from 'lib/i18n';

import SocialLinksWrap from './styled/SocialLinksWrap';
import SocialIconWrap from './styled/SocialIconWrap';
import { SocialLinksProps } from './types';

const SOCIAL_URLS = {
  facebook: 'https://www.facebook.com/sizeid.eu',
  instagram: 'https://www.instagram.com/sizeid/',
  linkedin: 'https://www.linkedin.com/company/sizeid-s-r-o-/',
  twitter: 'https://twitter.com/sizeid',
  youtube: 'https://www.youtube.com/channel/UCmIGJiVI2WW3j1hnjzAhXTA',
};
const SocialLinks: React.FC<SocialLinksProps> = props => (
  <SocialLinksWrap>
    {Object.keys(SOCIAL_URLS).map(site => (
      <a
        href={SOCIAL_URLS[site as keyof typeof SOCIAL_URLS]}
        key={site}
        target="_blank"
        rel="noopener noreferrer"
      >
        <SocialIconWrap>
          <SocialIcon image={site as SocialIconImage} size="18px" />
        </SocialIconWrap>
        {props.t(`footer.social_links.${site}`)}
      </a>
    ))}
  </SocialLinksWrap>
);

export default withTranslation('common')(SocialLinks);
