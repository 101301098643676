import { css, FlattenInterpolation, ThemeProps } from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';
import { CSSInterpolation } from '@sizeid/commons/types';

import { ThemedSectionProps } from '../types';

import { dimensions } from './wave';

const beforeWave = (props: ThemedSectionProps): CSSInterpolation =>
  props.beforeWave
    ? css`
        padding-bottom: ${props.theme.sizes.pageContent.mobileBottomPadding +
        dimensions.mobile}px;

        ${media.moreThan('sm')`
          padding-bottom: ${
            props.theme.sizes.pageContent.bottomPadding + dimensions.tablet
          }px;
        `};

        ${media.moreThan('md')`
          padding-bottom: ${
            props.theme.sizes.pageContent.bottomPadding + dimensions.desktop
          }px;
        `};

        ${media.moreThan('lg')`
          padding-bottom: ${
            props.theme.sizes.pageContent.bottomPadding + dimensions.large
          }px;
        `};
      `
    : '';

const padding = (
  props: ThemedSectionProps,
): FlattenInterpolation<ThemeProps<unknown>> => {
  const { topPadding, bottomPadding, mobileTopPadding, mobileBottomPadding } =
    props.theme.sizes.pageContent;

  return css`
    padding: ${props.topWave
        ? mobileTopPadding - dimensions.mobile
        : mobileTopPadding}px
      0
      ${props.bottomWave
        ? mobileBottomPadding - dimensions.mobile
        : mobileBottomPadding}px;

    ${media.moreThan('sm')`
      padding: ${props.topWave ? 0 : topPadding}px 0 ${
      props.bottomWave ? bottomPadding - dimensions.tablet : bottomPadding
    }px;
    `};

    ${media.moreThan('lg')`
      padding-bottom: ${
        props.bottomWave ? mobileBottomPadding : bottomPadding
      }px;
    `};

    ${beforeWave};
  `;
};

export default padding;
