import { NextRouter } from 'next/router';
import React, { ComponentType } from 'react';

import { getDisplayName } from 'utils/components';

import UrlConsumer from './Url';

const withUrl = <T extends { url: NextRouter } = { url: NextRouter }>(
  Component: ComponentType<T>,
): ComponentType<Omit<T, 'url'>> => {
  const ComponentWithUrl: React.FC<Omit<T, 'url'>> = props => (
    <UrlConsumer>
      {url => {
        const joinedProps = { ...props, url } as T;
        return <Component {...joinedProps} url={url} />;
      }}
    </UrlConsumer>
  );

  ComponentWithUrl.displayName = `WithUrl(${getDisplayName(
    Component as ComponentType,
  )})`;

  return ComponentWithUrl;
};

export default withUrl;
