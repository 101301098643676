import styled from 'styled-components';
import { key } from 'styled-theme';
import { media } from '@sizeid/commons/utils/styles';

import { CookieConsentState } from '../types';

export default styled.div.attrs((props: CookieConsentState) => ({
  style: {
    opacity: props.visible ? 1 : 0,
    zIndex: props.visible ? 99 : -1,
  },
}))<{ visible: boolean }>`
  width: 90%;
  padding: 16px;
  background: ${key('colors.black.default')};
  box-shadow: ${key('outlines.shadow.default')};
  border-radius: ${key('sizes.borderRadius')};
  color: ${key('colors.white')};
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  position: fixed;
  left: 5%;
  bottom: 3%;
  transition: opacity 0.33s ease-in-out;

  ${media.moreThan('sm')`
    width: auto;
    left: 2%;
    padding: 32px;
  `};

  button {
    margin-top: 16px;
  }
`;
