import styled from 'styled-components';
import { key } from 'styled-theme';
import { media } from '@sizeid/commons/utils/styles';

import { ThemedContentProps } from '../types';

const padding = (props: ThemedContentProps): string => {
  const headerHeight = props.noHeader ? 0 : props.theme.sizes.pageHeader.height;

  if (props.noPadding) {
    return `${headerHeight}px 0 0 0`;
  }

  const { topPadding, bottomPadding } = props.theme.sizes.pageContent;

  if (props.bottomPadding) {
    return `${topPadding + headerHeight}px 0 ${bottomPadding}px`;
  }

  return `${topPadding + headerHeight}px 0 0`;
};

export default styled.div<ThemedContentProps>`
  position: relative;
  z-index: 0;
  padding: ${padding};
  background: ${key('backgrounds.primary')};
  overflow: hidden;

  ${media.lessThan('sm')`
    font-size: ${key('text.mobileBaseSize')}px;
  `};
`;
