import React, { PureComponent, ComponentType } from 'react';

import { getDisplayName } from 'utils/components';

import { ComponentWithModalState, WithModalComponentProps } from './types';
import Modal from './Modal';

const withModal =
  <P extends WithModalComponentProps>(ModalContent?: ComponentType) =>
  (
    Component: ComponentType<P>,
  ): ComponentType<Omit<P, keyof WithModalComponentProps>> =>
    class ComponentWithModal extends PureComponent<
      Omit<P, keyof WithModalComponentProps>,
      ComponentWithModalState
    > {
      public static displayName = `WithModal(${getDisplayName(
        Component as ComponentType,
      )})`;

      public state = { isVisible: false };

      public handleShow = (): void => {
        if (this.state.isVisible) return;
        this.setState({ isVisible: true });
      };

      public handleHide = (): void => {
        if (!this.state.isVisible) return;
        this.setState({ isVisible: false });
      };

      public render(): JSX.Element {
        const joinedProps = {
          ...this.props,
          showModal: this.handleShow,
          hideModal: this.handleHide,
          modalVisible: this.state.isVisible,
        } as P;
        return (
          <>
            <Component {...joinedProps} />
            {this.state.isVisible && ModalContent && (
              <Modal onCloseClick={this.handleHide}>
                <ModalContent />
              </Modal>
            )}
          </>
        );
      }
    };

export default withModal;
