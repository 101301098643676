import React, { LegacyRef, MouseEvent } from 'react';

import { useTranslation } from 'lib/i18n';
import closest from 'utils/closest';

import { AdvisorTriggerProps } from './types';

export const BUTTON_CLASS = 'SizeID-button';

const handleOnClick = (props: AdvisorTriggerProps) => (event: MouseEvent) => {
  if (!props.disabled) {
    closest();
    SizeId.sizeIdConnect.createModalFromButton(
      (event.target as HTMLDivElement).closest(`.${BUTTON_CLASS}`),
    );
  }
};

const AdvisorTrigger = React.forwardRef(
  (props: AdvisorTriggerProps, ref: LegacyRef<HTMLDivElement>) => {
    const { i18n } = useTranslation();
    return (
      <div
        ref={ref}
        className={BUTTON_CLASS}
        role="button"
        data-sizeid-direct="1"
        data-sizeid-advisor-mode={props.advisorMode}
        data-sizeid-recommendation-mode={props.recommendationMode}
        data-sizeid-preselect-brand={props.preselectBrand}
        data-sizeid-language={i18n.language}
        onClick={handleOnClick(props)}
      >
        {props.children}
      </div>
    );
  },
);

export default AdvisorTrigger;
