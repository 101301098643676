import React, { useEffect, useState } from 'react';
import Box from '@sizeid/commons/components/Box';
import { Icon } from '@sizeid/commons/components/SvgImage';

import FlashMessageContainer from './styled/FlashMessageContainer';
import {
  FLASH_MESSAGE_TRANSITION,
  FLASH_MESSAGE_TTL,
  FLASH_MESSAGE_ICONS,
} from './constants';
import { FlashMessageProps, FlashMessageState } from './types';

const FlashMessage: React.FC<FlashMessageProps> = props => {
  const [state, setState] = useState<FlashMessageState>({
    visible: false,
    usedEffect: false,
  });
  let timeout: null | ReturnType<typeof setTimeout> = null;
  useEffect(() => {
    if (!state.usedEffect) {
      setState({
        visible: true,
        usedEffect: true,
      });
      if (props.options?.timeout === false) return;

      timeout = setTimeout(() => {
        setState({
          usedEffect: true,
          visible: false,
        });
        setTimeout(() => {
          if (props.deleteFlashMessage && props.id) {
            props.deleteFlashMessage(props.id);
          }
          if (typeof props.options.onClose === 'function') {
            props.options.onClose();
          }
        }, FLASH_MESSAGE_TRANSITION);
      }, props.options.timeout || FLASH_MESSAGE_TTL);
    }
  });
  const handleCloseFlashMessage = (): void => {
    if (timeout) {
      clearTimeout(timeout);
    }
    if (props.deleteFlashMessage && props.id) {
      props.deleteFlashMessage(props.id);
    }
  };
  return (
    <>
      <FlashMessageContainer options={props.options} visible={state.visible}>
        <Icon image={FLASH_MESSAGE_ICONS[props.options.type]} />

        <>{props.message}</>
        <Box
          marginRight={0}
          marginLeft="auto"
          onClick={handleCloseFlashMessage}
        >
          <Icon image="close" size=".75em" />
        </Box>
      </FlashMessageContainer>
    </>
  );
};

export default FlashMessage;
