import React from 'react';
import { WithTranslation } from 'next-i18next';

import Dropdown, { DropdownItem } from 'components/Dropdown';

import { dismissAndScroll, createAnchor } from '../utils';
import { REFERENCE, PRICING, PLATFORMS } from '../constants';

const BusinessDropdown: React.FC<WithTranslation> = props => (
  <Dropdown label={props.t('navigation.others')}>
    {toggleDropdownVisible => (
      <>
        <DropdownItem>
          <a
            href={createAnchor(REFERENCE)}
            onClick={dismissAndScroll(toggleDropdownVisible)}
          >
            {props.t('navigation.business.reference')}
          </a>
        </DropdownItem>
        <DropdownItem>
          <a
            href={createAnchor(PLATFORMS)}
            onClick={dismissAndScroll(toggleDropdownVisible)}
          >
            {props.t('navigation.business.platforms')}
          </a>
        </DropdownItem>
        <DropdownItem>
          <a
            href={createAnchor(PRICING)}
            onClick={dismissAndScroll(toggleDropdownVisible)}
          >
            {props.t('navigation.business.pricing')}
          </a>
        </DropdownItem>
      </>
    )}
  </Dropdown>
);

export default BusinessDropdown;
