import React from 'react';

import { handleScrollTo } from 'utils/scrollTo';

export const dismissAndScroll =
  (toggleDropdownVisible: React.MouseEventHandler) =>
  (event: React.MouseEvent): void => {
    toggleDropdownVisible(event);
    handleScrollTo()(event);
  };

export const createAnchor = (id: string): string => `#${id}`;
