import { NextPageContext } from 'next';
import nookies from 'nookies';

import getSession from 'lib/session';
import { authRedirect } from 'utils/navigation';
import { COOKIE_NAME } from 'components/CookieConsent/constants';
import { i18n } from 'lib/i18n';

import { AppProps, InitialAppProps, PageType } from './types';

// eslint-disable-next-line @typescript-eslint/ban-types

export default (
    Page: PageType<AppProps, Record<string, unknown>>,
    requireAuth?: boolean,
  ) =>
  async (
    context: NextPageContext,
  ): Promise<InitialAppProps | Record<string, never>> => {
    const { req, res } = context;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { apolloClient } = context;
    const { session } = await getSession(context, apolloClient);
    const authenticated = session && session.user;
    if (requireAuth && !authenticated) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const locale = (req ? req.language : i18n.language) || DEFAULT_LOCALE;
      authRedirect(req, res, locale);
      return {};
    }

    let pageInitialProps = {};

    if (Page.getInitialProps) {
      pageInitialProps = await Page.getInitialProps(context, session);
    }

    return {
      cookieConsent: Boolean(nookies.get(context)[COOKIE_NAME]),
      session,
      pageInitialProps,
    };
  };
