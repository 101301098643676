import React from 'react';
import { BUSINESS_INDEX } from '@sizeid/shared/constants/routes';
import { WithTranslation } from 'next-i18next';

import RouteLink from 'components/RouteLink';

import NavigationDrawerFlex from './styled/NavigationDrawerFlex';
import NavigationItem from './NavigationItem';
import NavigationItems from './styled/NavigationItems';
import SignButton from './SignButton';
import RecommendSize from './RecommendSize';
import { DefaultNavigationDropdown } from './NavigationDropdown';

const DefaultNavigation: React.FC<WithTranslation> = props => (
  <>
    <SignButton i18n={props.i18n} t={props.t} tReady={props.tReady} />
    <NavigationDrawerFlex>
      <NavigationItems>
        <RecommendSize i18n={props.i18n} t={props.t} tReady={props.tReady} />
        <NavigationItem>
          <RouteLink href={BUSINESS_INDEX.route} passHref>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a>{props.t('navigation.business.title')}</a>
          </RouteLink>
        </NavigationItem>
      </NavigationItems>
      <DefaultNavigationDropdown
        i18n={props.i18n}
        t={props.t}
        tReady={props.tReady}
      />
    </NavigationDrawerFlex>
  </>
);

export default DefaultNavigation;
