import styled from 'styled-components';
import { key } from 'styled-theme';
import { media } from '@sizeid/commons/utils/styles';

export default styled.ul`
  list-style: none;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  ${media.moreThan('sm')`
    flex-flow: row wrap;

    > li {
      width: auto;
      flex: 0 0 48.8%;
    }
  `};

  > li {
    display: block;
    border: ${key('outlines.border')};
    border-radius: ${key('sizes.borderRadius')};
    background: ${key('colors.white')};
    cursor: pointer;
    width: 100%;
    margin-top: 16px;

    > a {
      text-decoration: none;
      color: ${key('global.primary')};
      font-size: 18px;
      line-height: 1.4;
      font-weight: bold;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 14px 20px;

      .flag {
        margin-right: 16px;
      }
    }

    &.active,
    &:hover {
      border-color: ${key('global.primary')};
      box-shadow: ${key('outlines.shadow.active')};
    }
  }
`;
