import styled from 'styled-components';
import { key } from 'styled-theme';

import { FLASH_MESSAGE_TRANSITION } from '../constants';
import { FlashMessageContainerProps } from '../types';

export default styled.div.attrs<FlashMessageContainerProps>(
  (props: FlashMessageContainerProps) => ({
    style: {
      opacity: props.visible ? 1 : 0,
    },
  }),
)<FlashMessageContainerProps>`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  border-radius: ${key('sizes.borderRadius')};
  box-shadow: ${key('outlines.shadow.default')};
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  padding: 10px 20px;
  color: ${key('colors.white')};
  transition: opacity ${FLASH_MESSAGE_TRANSITION}ms;
  background: ${(props: FlashMessageContainerProps) =>
    props.theme.states[props.options.type].default};

  margin-bottom: 10px;

  &:first-child {
    margin-bottom: 0;
  }
`;
