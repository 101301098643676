import React from 'react';
import Box from '@sizeid/commons/components/Box';
import Button from '@sizeid/commons/components/Button';
import { Flag, Pictogram } from '@sizeid/commons/components/SvgImage';
import translation from '@sizeid/shared/lib/translation';

import LanguageSelector from 'components/LanguageSelector';
import { withModal } from 'components/Modal';
import { withTranslation } from 'lib/i18n';

import LanguageSelectWrap from './styled/LanguageSelectWrap';
import LanguageSelectCurrent from './styled/LanguageSelectCurrent';
import { LanguageSelectProps } from './types';

const LanguageSelect: React.FC<LanguageSelectProps> = props => (
  <LanguageSelectWrap>
    <LanguageSelectCurrent onClick={props.showModal}>
      <Flag image={props.i18n.language} size="42px" />
      <span>{translation.languages[props.i18n.language]}</span>
    </LanguageSelectCurrent>

    <Box marginTop={2} className="LanguageSelect-map">
      <Pictogram width="300px" height="125px" image="map" />
    </Box>

    <Box marginTop={2} className="LanguageSelect-btn">
      <Button onClick={props.showModal} outline large>
        {props.t('footer.select_language')}
      </Button>
    </Box>
  </LanguageSelectWrap>
);

export default withModal(LanguageSelector)(
  withTranslation('common')(LanguageSelect),
);
