import styled from 'styled-components';

import { MODAL_CONTAINER_CLASS } from '../constants';

export default styled.div`
  .${MODAL_CONTAINER_CLASS} {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    will-change: opacity;
    background: none;
    z-index: 998;
  }
`;
