export const ADVISOR_MODE_SELECT_SIZE_CHART = 'selectSizeChart' as const;
const ADVISOR_MODE_SIZE_CHART = 'sizeChart' as const;

export const RECOMMENDATION_MODE_SELECT = 'select' as const;
export const RECOMMENDATION_MODE_PROPERTIES = 'properties' as const;
const RECOMMENDATION_MODE_MEASURES = 'measures' as const;
const RECOMMENDATION_MODE_RECORDS = 'records' as const;
const RECOMMENDATION_MODE_FORCE_SELECT = 'forceSelect' as const;

type AdvisorMode =
  | typeof ADVISOR_MODE_SELECT_SIZE_CHART
  | typeof ADVISOR_MODE_SIZE_CHART;

type RecommendationMode =
  | typeof RECOMMENDATION_MODE_SELECT
  | typeof RECOMMENDATION_MODE_MEASURES
  | typeof RECOMMENDATION_MODE_RECORDS
  | typeof RECOMMENDATION_MODE_PROPERTIES
  | typeof RECOMMENDATION_MODE_FORCE_SELECT;

export type AdvisorTriggerProps = {
  advisorMode: AdvisorMode;
  disabled?: boolean;
  recommendationMode: RecommendationMode;
  preselectBrand?: string;
  children: JSX.Element;
};
