import { ApolloClient } from '@apollo/client';
import nookies from 'nookies';
import { NextPageContext } from 'next';
import { CookieSerializeOptions } from 'cookie';

import { Session, SessionCookie } from 'types';

import createSessionMutation from './mutations/createSession';
import getSessionQuery from './queries/getSession';

const createSession = async (
  cookie: string | null | undefined,
  ctx: NextPageContext,
  apolloClient: ApolloClient<unknown>,
): Promise<string> => {
  const { data } = await apolloClient.mutate<{
    createSession: SessionCookie;
  }>({
    mutation: createSessionMutation,
  });
  if (!data) return '';
  const { id, expires, ...rest } = data.createSession;

  const options: CookieSerializeOptions = {
    expires: new Date(expires),
    path: '/',
    sameSite: 'none',
    secure: true,
    ...rest,
    httpOnly: false,
  };

  nookies.set(ctx, SESSION_NAME, id, options as CookieSerializeOptions);

  return id;
};

const getSession = async (
  id: string,
  client: ApolloClient<unknown>,
): Promise<Session> => {
  const { data } = await client.query({
    query: getSessionQuery,
    variables: {
      id,
    },
  });

  return data.session;
};

export default async (
  ctx: NextPageContext,
  client: ApolloClient<unknown>,
): Promise<{ session: Session }> => {
  const sessionCookie = nookies.get(ctx)[SESSION_NAME];
  const id = await createSession(sessionCookie, ctx, client);
  const session = await getSession(id, client);
  return {
    session,
  };
};
