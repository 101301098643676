import React from 'react';
import {
  ABOUT_INDEX,
  COMPANY_INDEX,
  CONTACT_INDEX,
  PRESS_INDEX,
  TERMS_INDEX,
  PRIVACY_INDEX,
} from '@sizeid/shared/constants/routes';
import { WithTranslation } from 'next-i18next';

import Dropdown, { DropdownItem } from 'components/Dropdown';
import RouteLink from 'components/RouteLink';

const DefaultDropdown: React.FC<WithTranslation> = props => (
  <Dropdown label={props.t('navigation.others')}>
    <DropdownItem>
      <RouteLink href={ABOUT_INDEX.route} passHref>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <a>{props.t('navigation.about')}</a>
      </RouteLink>
    </DropdownItem>
    <DropdownItem>
      <RouteLink href={PRESS_INDEX.route} passHref>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <a>{props.t('navigation.press')}</a>
      </RouteLink>
    </DropdownItem>
    <DropdownItem>
      <RouteLink href={COMPANY_INDEX.route} passHref>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <a>{props.t('navigation.company')}</a>
      </RouteLink>
    </DropdownItem>
    <DropdownItem>
      <RouteLink href={TERMS_INDEX.route} passHref>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <a>{props.t('navigation.terms')}</a>
      </RouteLink>
    </DropdownItem>
    <DropdownItem>
      <RouteLink href={PRIVACY_INDEX.route} passHref>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <a>{props.t('navigation.privacy')}</a>
      </RouteLink>
    </DropdownItem>
    <DropdownItem>
      <RouteLink href={CONTACT_INDEX.route} passHref>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <a>{props.t('navigation.contact')}</a>
      </RouteLink>
    </DropdownItem>
  </Dropdown>
);

export default DefaultDropdown;
