import React, { useEffect, useState } from 'react';
import Button from '@sizeid/commons/components/Button';
import { INDEX, INTEGRATION_INDEX } from '@sizeid/shared/constants/routes';
import { WithTranslation } from 'next-i18next';

import RouteLink from 'components/RouteLink';

import { handleScrollTo } from '../../utils/scrollTo';

import NavigationDrawerFlex from './styled/NavigationDrawerFlex';
import { PLATFORMS, INTEGRATION_PRODUCTS } from './constants';
import NavigationItem from './NavigationItem';
import NavigationItems from './styled/NavigationItems';

type DocsNavigationProps = { hideMenu?: boolean } & WithTranslation;

const DocsNavigation: React.FC<DocsNavigationProps> = props => {
  const [state, setState] = useState<{
    platforms: string;
    set: boolean;
    products: string;
    handleScrollTo: boolean;
  }>({
    platforms: INTEGRATION_INDEX.route,
    set: false,
    products: INTEGRATION_INDEX.route,
    handleScrollTo: false,
  });

  useEffect(() => {
    if (process.browser && !state.set) {
      const { pathname } = window.location;
      const slashIndex = pathname.indexOf('/', 1);
      const path = pathname.slice(slashIndex);
      const link =
        path === INTEGRATION_INDEX.route ? '' : INTEGRATION_INDEX.route;
      const platforms = `${link}#${PLATFORMS}`;
      const products = `${link}#${INTEGRATION_PRODUCTS}`;
      // eslint-disable-next-line react/no-did-mount-set-state
      setState({
        platforms,
        set: true,
        products,
        handleScrollTo: path === INTEGRATION_INDEX.route,
      });
    }
  }, []);

  const onNavigationClick = state.handleScrollTo ? handleScrollTo() : undefined;

  return (
    <>
      {!props.hideMenu && (
        <NavigationDrawerFlex>
          <NavigationItems>
            <NavigationItem>
              <RouteLink href={state.platforms}>
                <a href={state.platforms} onClick={onNavigationClick}>
                  {props.t('navigation.integration.platforms')}
                </a>
              </RouteLink>
            </NavigationItem>
            <NavigationItem>
              <RouteLink href={state.products}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a role="link" onClick={onNavigationClick}>
                  {props.t('navigation.integration.products')}
                </a>
              </RouteLink>
            </NavigationItem>
          </NavigationItems>
        </NavigationDrawerFlex>
      )}
      <RouteLink href={INDEX.route} passHref>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <a>
          <Button white outline>
            {props.t('navigation.business.backToHome')}
          </Button>
        </a>
      </RouteLink>
    </>
  );
};

export default DocsNavigation;
