import React from 'react';

import NavigationItemContainer from './styled/NavigationItemContainer';
import { NavigationItemProps } from './types';

const NavigationItem: React.FC<NavigationItemProps> = props => (
  <NavigationItemContainer>{props.children}</NavigationItemContainer>
);

export default NavigationItem;
