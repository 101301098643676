import theme from 'themes/default';

const defaultDuration = 500;

const getElementPosition = (elementId: string): number | null => {
  const element = document.getElementById(elementId);
  if (!element) return null;
  return window.pageYOffset + element.getBoundingClientRect().top;
};

// https://gist.github.com/gre/1650294
const easing = (t: number): number =>
  t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

const scrollTo = (
  elementId: string,
  duration: number = defaultDuration,
): void => {
  const elementPosition = getElementPosition(elementId);

  if (!elementPosition || !document.body) return;

  const startingPosition = window.pageYOffset;
  const targetPosition =
    document.body.scrollHeight - elementPosition < window.innerHeight
      ? document.body.scrollHeight - window.innerHeight
      : elementPosition;

  let start: number | null = null;
  const diff =
    targetPosition - startingPosition - theme.sizes.pageHeader.height;

  if (!diff) return;

  window.requestAnimationFrame(function step(timestamp: number) {
    if (!start) {
      start = timestamp;
    }

    const time = timestamp - start;
    let percentage = Math.min(time / duration, 1);

    percentage = easing(percentage);
    window.scrollTo(0, startingPosition + diff * percentage);

    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
};

const hasHrefAttribute = (target: HTMLElement): { value: string } | undefined =>
  target &&
  target.attributes &&
  (
    target.attributes as HTMLElement['attributes'] & {
      href?: { value: string };
    }
  ).href;

export const handleScrollTo =
  (duration: number = defaultDuration) =>
  (event: React.MouseEvent | null): void => {
    if (event) {
      event.preventDefault();
    }
    const targetNodeHref = hasHrefAttribute(event?.target as HTMLElement);
    let parentNodeHref = null;
    if (event?.target) {
      parentNodeHref = hasHrefAttribute(
        (event.target as HTMLElement).parentNode as HTMLElement,
      );
    }

    if (!targetNodeHref && !parentNodeHref) {
      return;
    }
    const href = targetNodeHref || parentNodeHref;

    scrollTo(`${href?.value.replace('#', '')}`, duration);
  };
