import styled from 'styled-components';
import { key } from 'styled-theme';
import { elementSize, media } from '@sizeid/commons/utils/styles';

const size = 32;

export default styled.button`
  ${elementSize(size * 2)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: none;
  border: none;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  color: ${key('global.primary')};
  background: none;

  ${media.moreThan('sm')`
    ${elementSize(size)};
    border-radius: 100%;
    background: ${key('global.primary')};
    color: white;
    top: -${size / 2}px;
    right: -${size / 2}px;
  `};
`;
