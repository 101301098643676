import styled, { css } from 'styled-components';
import { key } from 'styled-theme';
import { TextStyle } from '@sizeid/commons/components/Text/styled/TextContainer';
import { media } from '@sizeid/commons/utils/styles';
import { TextContainerProps } from '@sizeid/commons/components/Text/types';

interface Props extends TextContainerProps {
  lead?: boolean;
  bigOnDesktop?: boolean;
}

export default styled.p<Props>`
  color: ${key('text.paragraph.color')};
  line-height: 1.4;
  ${TextStyle};

  ${(props: Props) =>
    props.lead &&
    css`
      margin-bottom: 40px;

      ${media.moreThan('sm')`
      margin-bottom: 64px;
    `};
    `};

  ${(props: Props) =>
    props.bigOnDesktop
      ? media.moreThan('smPlus')`
    font-size: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  `
      : ''};
`;
