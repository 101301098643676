import styled from 'styled-components';
import { key } from 'styled-theme';
import StyledAnchor from '@sizeid/commons/components/Button/styled/StyledAnchor';
import StyledButtonContent from '@sizeid/commons/components/Button/styled/StyledButtonContent';
import { media } from '@sizeid/commons/utils/styles';

import DropdownButton from 'components/Dropdown/styled/DropdownButton';
import DropdownItem from 'components/Dropdown/styled/DropdownItem';
import DropdownItems from 'components/Dropdown/styled/DropdownItems';

import NavigationItemContainer, {
  navigationItemStyle,
} from '../NavigationItem/styled/NavigationItemContainer';

export const navigationDrawerWidth = '240px';

export default styled.div<{ visible: boolean }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  background: ${key('colors.white')};
  padding: ${key('sizes.pageHeader.height')}px 32px 32px 32px;
  width: ${navigationDrawerWidth};
  height: 100%;

  ${NavigationItemContainer} a, ${StyledAnchor} {
    color: ${key('colors.black.light[400]')};
    border-color: ${key('colors.black.light[400]')};
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
      color: ${key('colors.black.light[400]')};
      border-color: ${key('colors.black.light[400]')};
    }
  }

  ${StyledAnchor} {
    margin-${key('navigation.anchorMargin')}: 15px;
  }

  ${StyledAnchor} ${StyledButtonContent} {
    ${navigationItemStyle};
  }

  ${media.lessThan('md')`
    color: ${key('colors.black.light[400]')};

    ${DropdownButton} {
      display: none;
    }

    ${DropdownItem} {
      margin-top: 15px;

      a {
        color: ${key('colors.black.light[400]')};
        background: none;
        font-weight: 500;
        text-align: center;
        padding: 0;
      }
    }

    ${DropdownItems} {
      display: block !important;
      position: relative;
      box-shadow: none;
      background: none;
      margin: 0;
      padding: 0;
    }
  `};

  ${media.moreThan('md')`
    background: none;
    padding: 0;
    width: auto;
    flex-flow: ${key('navigation.flexDirection')} wrap;

    > a {
      margin: 0 auto 0 30px;
    }

    ${NavigationItemContainer} > a,
    > a {
      color: ${key('colors.white')};
      border-color: ${key('colors.white')};

      &:active,
      &:focus,
      &:hover {
        color: ${key('colors.white')};
        border-color: ${key('colors.white')};
      }
    }
  `};
`;
