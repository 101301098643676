import styled from 'styled-components';
import { elementSize, media } from '@sizeid/commons/utils/styles';

export default styled.div`
  text-align: center;

  .logo {
    ${elementSize(8, 'em')};

    ${media.moreThan('sm')`
      ${elementSize(10, 'em')};
    `};
  }
`;
