import React from 'react';
import { withTheme } from 'styled-components';
import { Logo as SvgLogo } from '@sizeid/commons/components/SvgImage';

import RouteLink from 'components/RouteLink';

import LogoContainer from './styled/LogoContainer';
import { LogoProps } from './types';

const Logo: React.FC<LogoProps> = props => (
  <LogoContainer scrollingHeader={props.scrollingHeader} scroll={props.scroll}>
    <RouteLink href={props.theme.logo.href.route} passHref>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a>
        <SvgLogo image={props.theme.logo.image} />
      </a>
    </RouteLink>
  </LogoContainer>
);

export default withTheme(Logo);
