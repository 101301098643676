import styled from 'styled-components';
import { ifProp } from 'styled-tools';
import { key } from 'styled-theme';
import { media } from '@sizeid/commons/utils/styles';

export default styled.div<{ visible: boolean }>`
  display: none;

  ${media.lessThan('md')`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    line-height: 15px;
    border: none;
    outline: none;
    text-decoration: none;
    position: absolute;
    left: -${ifProp('visible', 0, key('sizes.pageHeader.height'))}px;
    width: ${key('sizes.pageHeader.height')}px;
    height: ${key('sizes.pageHeader.height')}px;
    cursor: pointer;
    z-index: 100;
    transition: all .25s ease;
    color: ${ifProp(
      'visible',
      key('colors.black.light[400]'),
      key('colors.white'),
    )};

    button {
      width: 24px;
      height: 16px;
      position: relative;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;
      margin-bottom: 5px;
      border: none;
      background: none;
      outline: none;

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        border-radius: ${key('sizes.borderRadius')};
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        background: ${ifProp(
          'visible',
          key('colors.black.light[400]'),
          key('colors.white'),
        )};

        &:first-child {
          top: ${ifProp('visible', '6px', 0)};
          width: ${ifProp('visible', 0, '100%')};
          left: ${ifProp('visible', '50%', '')};
        }

        &:nth-child(2) {
          top: 6px;
          transform: ${ifProp('visible', 'rotate(45deg)', '')};
        }

        &:nth-child(3) {
          top: 6px;
          transform: ${ifProp('visible', 'rotate(-45deg)', '')};
        }

        &:nth-child(4) {
          top: ${ifProp('visible', '10px', '12px')};
          width: ${ifProp('visible', 0, '100%')};
          left: ${ifProp('visible', '50%', '')};
        }
      }
    }
  `};
`;
