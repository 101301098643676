import styled from 'styled-components';
import { key } from 'styled-theme';

export default styled.li`
  margin: 0;
  padding: 0;
  text-align: left;

  a {
    color: ${key('colors.black.light[400]')};
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 24px;
    display: block;
    white-space: nowrap;

    &:active,
    &:focus,
    &:hover {
      color: ${key('colors.black.default')};
      background: ${key('colors.black.light[100]')};
    }
  }
`;
