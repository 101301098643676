import { gql } from '@apollo/client';

export default gql`
  query getSessionQuery($id: String) {
    session(id: $id) {
      id
      user {
        id
        model_id
        email
        profile_name
        profile_picture
        password
        activated
      }
    }
  }
`;
