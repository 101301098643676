import React from 'react';
import NProgress from 'nprogress';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { NextPage } from 'next';
import { useRouter } from 'next/router';

import { Theme } from 'constants/index';
import CookieConsent from 'components/CookieConsent';
import { ConfirmProvider } from 'components/Confirm';
import { FlashMessagesProvider } from 'components/FlashMessages';
import { ModalRoot } from 'components/Modal';
import { SessionProvider } from 'components/Session';
import { UrlProvider } from 'components/Url';
import { Router } from 'lib/i18n';

import getInitialProps from './getInitialProps';
import getReduxStore from './getReduxStore';
import loadTheme from './loadTheme';
import { AppProps, InitialAppProps, PageType } from './types';

export type { PageType } from './types';

Router.events.on('routeChangeComplete', () => {
  NProgress.start();
});

Router.events.on('routeChangeError', () => {
  NProgress.done();
});
Router.events.on('routeChangeComplete', () => {
  window.scrollTo(0, 0);
  NProgress.done();
});

const app = (
  Page: PageType<AppProps, Record<string, unknown>>,
  options?: {
    requireAuth?: boolean;
    theme?: Theme;
  },
): React.FC<AppProps> => {
  const { requireAuth, theme } = options || {};
  const App: NextPage<AppProps, InitialAppProps | Record<string, never>> =
    props => {
      const reduxStore = getReduxStore();
      const router = useRouter();
      return (
        <Provider store={reduxStore}>
          <ThemeProvider theme={loadTheme(theme)}>
            <SessionProvider value={props.session}>
              <UrlProvider value={router}>
                <FlashMessagesProvider>
                  <ConfirmProvider>
                    <Page
                      session={props.session}
                      url={router}
                      cookieConsent={props.cookieConsent}
                      {...props.pageInitialProps}
                    />
                    <CookieConsent accepted={props.cookieConsent} />
                    <ModalRoot />
                  </ConfirmProvider>
                </FlashMessagesProvider>
              </UrlProvider>
            </SessionProvider>
          </ThemeProvider>
        </Provider>
      );
    };

  App.getInitialProps = getInitialProps(Page, requireAuth);

  return App;
};

export default app;
