import React, { PureComponent } from 'react';
import moment from 'moment';
import Button from '@sizeid/commons/components/Button';
import nookies from 'nookies';

import { withTranslation } from 'lib/i18n';

import CookieConsentContainer from './styled/CookieConsentContainer';
import { COOKIE_EXPIRES, COOKIE_LINK, COOKIE_NAME } from './constants';
import { CookieConsentProps, CookieConsentState } from './types';

class CookieConsent extends PureComponent<
  CookieConsentProps,
  CookieConsentState
> {
  public state = { visible: false };

  public componentDidMount(): void {
    setTimeout(() => {
      if (this.props.accepted) return;
      this.setState({ visible: true });
    }, 100);
  }

  public handleAccept = (): void => {
    this.setState({ visible: false });
    nookies.set(null, COOKIE_NAME, 'true', {
      expires: moment()
        .add(...COOKIE_EXPIRES)
        .toDate(),
    });
  };

  public render(): JSX.Element {
    if (this.props.accepted) return <></>;
    return (
      <CookieConsentContainer visible={this.state.visible}>
        {this.props.t('cookieConsent.text')}
        <br />
        <a href={COOKIE_LINK} target="_blank" rel="noreferrer">
          {this.props.t('cookieConsent.link')}
        </a>
        <br />
        <Button onClick={this.handleAccept}>
          {this.props.t('cookieConsent.button')}
        </Button>
      </CookieConsentContainer>
    );
  }
}

export default withTranslation('common')(CookieConsent);
