import React from 'react';

import Navigation from 'components/Navigation';
import Wrapper from 'components/Page/Wrapper';

import HeaderContainer from './styled/HeaderContainer';
import Logo from './Logo';
import { HeaderProps } from './types';

const Header: React.FC<HeaderProps> = props => (
  <HeaderContainer withScroll={props.withScroll} scroll={props.scroll}>
    <Wrapper>
      <>
        <Logo scrollingHeader={props.withScroll} scroll={props.scroll} />
        <Navigation hideMenu={props.hideMenu} />
      </>
    </Wrapper>
  </HeaderContainer>
);

export default Header;
