import React from 'react';
import Box from '@sizeid/commons/components/Box';
import Text from '@sizeid/commons/components/Text';
import { Logo } from '@sizeid/commons/components/SvgImage';
import { nlBr } from '@sizeid/commons/utils/strings';
import {
  ABOUT_INDEX,
  BUSINESS_INDEX,
  COMPANY_INDEX,
  CONTACT_INDEX,
  PRESS_INDEX,
  TERMS_INDEX,
  PRIVACY_INDEX,
  INTEGRATION_INDEX,
} from '@sizeid/shared/constants/routes';

import {
  ADVISOR_MODE_SELECT_SIZE_CHART,
  RECOMMENDATION_MODE_PROPERTIES,
} from 'components/AdvisorTrigger/types';
import RouteLink from 'components/RouteLink';
import AdvisorTrigger from 'components/AdvisorTrigger';
import { withTranslation } from 'lib/i18n';

import Wrapper from '../Wrapper';

import LightFooterContainer from './styled/LightFooterContainer';
import LightFooterContainerInner from './styled/LightFooterContainerInner';
import DarkFooterContainer from './styled/DarkFooterContainer';
import DarkFooterContainerInner from './styled/DarkFooterContainerInner';
import SocialLinks from './SocialLinks';
import LanguageSelect from './LanguageSelect';
import { FooterProps } from './types';

const Footer: React.FC<FooterProps> = props => (
  <>
    <LightFooterContainer>
      <Wrapper>
        <LightFooterContainerInner>
          <ul>
            <li>
              <AdvisorTrigger
                advisorMode={ADVISOR_MODE_SELECT_SIZE_CHART}
                recommendationMode={RECOMMENDATION_MODE_PROPERTIES}
              >
                {props.t('navigation.advisor')}
              </AdvisorTrigger>
            </li>
            <li>
              <RouteLink href={BUSINESS_INDEX.route}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a>{props.t('navigation.business.title')}</a>
              </RouteLink>
            </li>
            <li>
              <RouteLink href={INTEGRATION_INDEX.route}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a>{props.t('navigation.integration.title')}</a>
              </RouteLink>
            </li>
            <li>
              <RouteLink href={ABOUT_INDEX.route}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a>{props.t('navigation.about')}</a>
              </RouteLink>
            </li>
            <li>
              <RouteLink href={PRESS_INDEX.route}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a>{props.t('navigation.press')}</a>
              </RouteLink>
            </li>
            <li>
              <RouteLink href={COMPANY_INDEX.route}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a>{props.t('navigation.company')}</a>
              </RouteLink>
            </li>
            <li>
              <RouteLink href={TERMS_INDEX.route}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a>{props.t('navigation.terms')}</a>
              </RouteLink>
            </li>
            <li>
              <RouteLink href={PRIVACY_INDEX.route}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a>{props.t('navigation.privacy')}</a>
              </RouteLink>
            </li>
            <li>
              <RouteLink href={CONTACT_INDEX.route}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a>{props.t('navigation.contact')}</a>
              </RouteLink>
            </li>
          </ul>
          <SocialLinks />
          <LanguageSelect />
        </LightFooterContainerInner>
      </Wrapper>
    </LightFooterContainer>
    <DarkFooterContainer>
      <Wrapper>
        <DarkFooterContainerInner>
          <Logo image="sizeid" size="115px" />
          <Box flex="0 1 480px">
            <Text size={-2} lineHeight="22px">
              {nlBr(props.t('footer.copy', { year: new Date().getFullYear() }))}
            </Text>
          </Box>
          <a
            href="http://wavevision.eu/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Logo image="powered-by-wavevision" size="150px" />
          </a>
        </DarkFooterContainerInner>
      </Wrapper>
    </DarkFooterContainer>
  </>
);

export default withTranslation('common')(Footer);
