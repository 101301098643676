import styled from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  ${media.lessThan('sm')`
    flex-direction: column;
    align-items: center;
  `};
`;
