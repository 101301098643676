import React, { useState } from 'react';
import { random } from '@sizeid/commons/utils/strings';

import FlashMessageComponent from './FlashMessage';
import FlashMessagesContainer from './styled/FlashMessagesContainer';
import {
  FlashMessage,
  FlashMessageOptions,
  FlashMessagesProviderProps,
  FlashMessagesProviderState,
  ShowFlashMessage,
  DeleteFlashMessage,
} from './types';
import flashMessageContext from './flashMessageContext';

const FlashMessagesProvider: React.FC<FlashMessagesProviderProps> = props => {
  const [state, setState] = useState<FlashMessagesProviderState>({
    flashMessages: [],
  });

  const showFlashMessage: ShowFlashMessage = (
    message: string,
    options: FlashMessageOptions,
  ) => {
    const flashMessage: FlashMessage = {
      id: `flash-${random()}`,
      message,
      options,
    };

    setState({
      flashMessages: [...state.flashMessages, flashMessage],
    });

    return flashMessage;
  };

  const deleteFlashMessage: DeleteFlashMessage = (id: string) => {
    const flashMessages = state.flashMessages.filter(m => m.id !== id);
    if (flashMessages !== state.flashMessages) {
      setState({ flashMessages });
    }
  };

  return (
    <>
      <FlashMessagesContainer>
        {state.flashMessages.map(flashMessage => (
          <FlashMessageComponent
            {...flashMessage}
            key={flashMessage.id}
            deleteFlashMessage={deleteFlashMessage}
          />
        ))}
      </FlashMessagesContainer>
      <flashMessageContext.Provider value={{ showFlashMessage }}>
        {props.children}
      </flashMessageContext.Provider>
    </>
  );
};
export default FlashMessagesProvider;
