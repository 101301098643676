import React from 'react';

import PContainer from './styled/PContainer';
import { PProps } from './types';

const P: React.FC<PProps> = props => {
  const { onClick, ...textProps } = props;
  return <PContainer onClick={onClick} {...textProps} />;
};

export default P;
