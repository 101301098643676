import React from 'react';
import { Logo } from '@sizeid/commons/components/SvgImage';
import { INDEX } from '@sizeid/shared/constants/routes';

import RouteLink from 'components/RouteLink';

import NoHeaderContainer from './styled/NoHeaderContainer';

const NoHeader: React.FC = () => (
  <NoHeaderContainer>
    <RouteLink href={INDEX.route} passHref>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a>
        <Logo image="sizeid-colored" />
      </a>
    </RouteLink>
  </NoHeaderContainer>
);

export default NoHeader;
