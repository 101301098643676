import styled from 'styled-components';
import { key } from 'styled-theme';
import { media } from '@sizeid/commons/utils/styles';
import { CSSProperties } from 'react';

import WrapperContainer from '../../Wrapper/styled/WrapperContainer';
import { HeaderContainerProps } from '../types';

export const scrollTransition = '0.5s ease';

export default styled.div.attrs((props: HeaderContainerProps) => {
  let style: CSSProperties = { height: props.theme.sizes.pageHeader.bigHeight };

  if (!props.withScroll || (props.withScroll && props.scroll > 0)) {
    style = {
      height: props.theme.sizes.pageHeader.height,
      boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.2)',
    };
  }

  return { style };
})<{ withScroll?: boolean; scroll: number }>`
  width: ${key('sizes.pageHeader.width')};
  color: ${key('colors.white')};
  background: ${key('backgrounds.header')};
  position: fixed;
  top: 0;
  z-index: 98;
  transition: height ${scrollTransition};
  will-change: height;

  > ${WrapperContainer} {
    position: initial;
    text-align: center;
    height: 100%;

    ${media.moreThan('sm')`
      text-align: left;
    `};
  }

  @media (min-width: 700px) {
    ${WrapperContainer} {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  ${media.moreThan('sm')`
    ${WrapperContainer} {
      display: flex;
      justify-content: space-between;
       align-items: center;
    }
  `};
`;
