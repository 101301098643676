import styled from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

export default styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: white;
  border-radius: 100%;
  margin-right: 15px;

  ${media.lessThan('sm')`
    margin-right: 0;
    margin-bottom: 10px;
  `};
`;
