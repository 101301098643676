import styled from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

import { StyledProps } from 'types';

export default styled.div`
  width: 90%;
  margin: 0 5%;
  left: 0;
  z-index: 999;
  display: flex;
  flex-flow: column-reverse;
  position: fixed;
  top: ${(props: StyledProps) => props.theme.sizes.pageHeader.height + 20}px;

  ${media.moreThan('lg')`
    width: 1200px;
    margin: 0 0 0 -600px;
    left: 50%;
  `};
`;
