import React, { ComponentType } from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'utils/components';
import { ConfirmProps } from 'types';

const withConfirm = <
  P extends { confirm: (callback: () => void, message?: string) => void },
>(
  Component: ComponentType<P>,
): React.FC<Omit<P, 'confirm'>> => {
  const ComponentWithConfirm = (
    props: Omit<P, 'confirm'>,
    context: ConfirmProps,
  ): JSX.Element => {
    const joinedProps = { ...props, confirm: context.confirm } as P;
    return <Component {...joinedProps} />;
  };

  ComponentWithConfirm.displayName = `WithConfirm${getDisplayName(
    Component as ComponentType,
  )}`;
  ComponentWithConfirm.contextTypes = {
    confirm: PropTypes.func.isRequired,
  };

  return ComponentWithConfirm;
};

export default withConfirm;
