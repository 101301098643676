import styled from 'styled-components';

import { ThemedSectionProps } from '../types';

import background from './background';
import jumbotron from './jumbotron';
import padding from './padding';
import wave from './wave';

export default styled.div<ThemedSectionProps>`
  position: relative;

  ${padding}
  ${background}
  ${jumbotron};
  ${wave};

  h2:first-of-type {
    margin-top: 0;
    padding-top: 20px;
  }

  ${(props: ThemedSectionProps) => props.css || ''};
`;
