import styled from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

export default styled.div`
  a {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  ${media.lessThan('sm')`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;

    a {
      font-size: 12px;
      flex-direction: column;
    }
  `};

  ${media.lessThan('370px')`
    justify-content: center;

    a {
      margin-left: 4px;
      margin-right: 4px;
    }
  `};
`;
