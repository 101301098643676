import React from 'react';
import Link, { LinkProps } from 'next/link';

const RouteLink: React.FC<LinkProps> = props => {
  return (
    <Link {...props} passHref>
      {props.children}
    </Link>
  );
};

export default RouteLink;
