import React, { useContext } from 'react';

import flashMessageContext from './flashMessageContext';
import {
  FlashMessageOptions,
  ShowFlashMessage,
  WithFlashMessages,
} from './types';

const withFlashMessages =
  <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    P extends WithFlashMessages = WithFlashMessages,
  >( // eslint-disable-line @typescript-eslint/no-explicit-any
    Component: React.ComponentType<P>,
  ) =>
  (props: Omit<P, keyof WithFlashMessages>): JSX.Element => {
    const flashMessage = useContext(flashMessageContext);
    const showFlashMessageHandler: ShowFlashMessage = (
      message: string,
      options: FlashMessageOptions,
    ) => {
      return flashMessage.showFlashMessage(message, options);
    };
    const joinedProps = {
      showFlashMessage: showFlashMessageHandler,
      ...props,
    } as P;
    return <Component {...joinedProps} />;
  };

export default withFlashMessages;
