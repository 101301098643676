import { CSSInterpolation } from '@sizeid/commons/types';
import styled, { css } from 'styled-components';
import { key } from 'styled-theme';

import { scrollTransition } from '../../styled/HeaderContainer';
import { LogoProps } from '../types';

const dimensions = (props: LogoProps): CSSInterpolation => {
  if (!props.scrollingHeader || (props.scrollingHeader && props.scroll > 0)) {
    return css`
      width: ${key('logo.width.normal')};
      height: ${key('logo.height.normal')};
    `;
  }

  return css`
    width: ${key('logo.width.big')};
    height: ${key('logo.height.big')};
  `;
};

export default styled.div<LogoProps>`
  > a {
    color: ${key('colors.white')};
    display: inline-block;
    height: 100%;
  }

  .logo {
    stroke: currentColor;
    fill: currentColor;
    transition: all ${scrollTransition};
    ${dimensions};
  }
`;
