import React, { PureComponent } from 'react';
import withClickOutside from 'react-click-outside';
import { Icon } from '@sizeid/commons/components/SvgImage';

import ModalContainer from './styled/ModalContainer';
import ModalClose from './styled/ModalClose';
import { ModalContainerProps } from './types';

class ModalContent extends PureComponent<ModalContainerProps> {
  public handleClickOutside(): void {
    this.props.onClose();
  }

  public render(): JSX.Element {
    return (
      <ModalContainer role="dialog" aria-modal="true">
        <ModalClose onClick={this.props.onClose}>
          <Icon image="close" />
        </ModalClose>
        {this.props.children}
      </ModalContainer>
    );
  }
}

export default withClickOutside(ModalContent);
