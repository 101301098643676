import React from 'react';
import Box from '@sizeid/commons/components/Box';
import Button from '@sizeid/commons/components/Button';

import P from 'components/typo/P';
import { withTranslation } from 'lib/i18n';

import { ConfirmModalProps } from './types';

const ConfirmModal: React.FC<ConfirmModalProps> = props => (
  <>
    <P size={2} centered>
      {props.message || props.t('confirm.default')}
    </P>
    <Box paddingTop="20px" row justify>
      <Box marginRight="15px">
        <Button onClick={props.onConfirm}>{props.t('confirm.ok')}</Button>
      </Box>
      <Box>
        <Button onClick={props.onCancel} outline>
          {props.t('confirm.cancel')}
        </Button>
      </Box>
    </Box>
  </>
);

export default withTranslation()(ConfirmModal);
