import React from 'react';
import { Flag } from '@sizeid/commons/components/SvgImage';
import translation from '@sizeid/shared/lib/translation';
import { WithTranslation } from 'next-i18next';

import { H2 } from 'components/typo/Heading';
import { Router, withTranslation } from 'lib/i18n';

import LanguagesList from './styled/LanguagesList';

const LanguageSelector: React.FC<WithTranslation> = ({ i18n, t }) => {
  const changeLanguage = (language: string) => (event: MouseEvent) => {
    event.preventDefault();

    if (language === i18n.language) return;

    i18n.changeLanguage(language);
  };

  const resolveAsPath = (language: string): string =>
    Router.asPath.replace(i18n.language, language);

  return (
    <>
      <H2 css="font-size: 24px; line-height: 32px; margin-bottom: 30px; text-align: center;">
        {t('languageSelector')}
      </H2>
      <LanguagesList>
        {translation.webLocales.map((language: string) => (
          <li
            key={language}
            className={language === i18n.language ? 'active' : undefined}
          >
            <a
              href={resolveAsPath(language)}
              onClick={() => changeLanguage(language)}
            >
              <Flag image={language} size="44px" />
              {translation.languages[language]}
            </a>
          </li>
        ))}
      </LanguagesList>
    </>
  );
};

export default withTranslation()(LanguageSelector);
