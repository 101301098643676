import { ThemedSectionProps } from '../types';

const background = (props: ThemedSectionProps): string => {
  if (props.sidbColor) return `background: ${props.theme.colors.SIDB.default};`;
  if (props.white) return `background: ${props.theme.colors.white}`;
  if (props.gray) return `background: ${props.theme.backgrounds.graySection};`;
  if (props.headerColor) {
    return `background: ${props.theme.backgrounds.header};`;
  }
  if (props.background) {
    return `background: url('${props.background}') center bottom no-repeat; background-size: cover;`;
  }
  return '';
};

export default background;
