import styled from 'styled-components';
import { key } from 'styled-theme';
import { media } from '@sizeid/commons/utils/styles';

import WrapperContainer from 'components/Page/Wrapper/styled/WrapperContainer';
import { BUTTON_CLASS } from 'components/AdvisorTrigger';

export default styled.div`
  background: ${key('colors.black.light.100')};
  position: relative;
  z-index: 1;
  padding-top: 110px;
  padding-bottom: 90px;
  font-weight: bold;

  ${WrapperContainer} {
    text-align: left;
  }

  ul {
    padding: 0;

    li {
      list-style: none;
    }

    a,
    .${BUTTON_CLASS} {
      cursor: pointer;
      padding: 5px 0;
    }
  }

  a,
  .${BUTTON_CLASS} {
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &,
  a,
  a:hover {
    color: ${key('colors.black.light.400')};
  }

  ${media.lessThan('sm')`
    padding-top: 40px;
    padding-bottom: 20px;

    li {
      text-align: center;
    }
  `};
`;
