import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { renderModal, withModal } from 'components/Modal';

import ConfirmModal from './ConfirmModal';
import { ConfirmProviderProps } from './types';

class ConfirmProvider extends PureComponent<ConfirmProviderProps> {
  public static childContextTypes = {
    confirm: PropTypes.func.isRequired,
  };

  public callback: (() => void) | null | undefined = null;

  public message: string | null | undefined = null;

  public getChildContext(): {
    confirm: (callback: () => void, message: string | null | undefined) => void;
  } {
    return {
      confirm: this.confirm,
    };
  }

  public confirm = (
    callback: () => void,
    message: string | null | undefined = null,
  ): void => {
    this.callback = callback;
    this.message = message;
    this.props.showModal();
  };

  public handleConfirm = (): void => {
    if (typeof this.callback === 'function') {
      this.callback();
    }

    this.props.hideModal();
  };

  public render(): JSX.Element {
    return (
      <>
        {this.props.children}
        {renderModal(
          <ConfirmModal
            message={this.message}
            onConfirm={this.handleConfirm}
            onCancel={this.props.hideModal}
          />,
          this.props,
        )}
      </>
    );
  }
}

export default withModal()(ConfirmProvider);
