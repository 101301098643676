import React from 'react';
import Button from '@sizeid/commons/components/Button';
import { INDEX } from '@sizeid/shared/constants/routes';
import { WithTranslation } from 'next-i18next';

import RouteLink from 'components/RouteLink';
import { handleScrollTo } from 'utils/scrollTo';

import NavigationDrawerFlex from './styled/NavigationDrawerFlex';
import NavigationItem from './NavigationItem';
import NavigationItems from './styled/NavigationItems';
import { BusinessNavigationDropdown } from './NavigationDropdown';
import { createAnchor } from './utils';
import { WHERE_TO_USE, BENEFITS, EXPERIENCE } from './constants';

type BusinessNavigationProps = { hideMenu?: boolean } & WithTranslation;

const BusinessNavigation: React.FC<BusinessNavigationProps> = props => (
  <>
    {!props.hideMenu && (
      <NavigationDrawerFlex>
        <NavigationItems>
          <NavigationItem>
            <a href={createAnchor(BENEFITS)} onClick={handleScrollTo()}>
              {props.t('navigation.business.benefits')}
            </a>
          </NavigationItem>
          <NavigationItem>
            <a href={createAnchor(WHERE_TO_USE)} onClick={handleScrollTo()}>
              {props.t('navigation.business.whereToUse')}
            </a>
          </NavigationItem>
          <NavigationItem>
            <a href={createAnchor(EXPERIENCE)} onClick={handleScrollTo()}>
              {props.t('navigation.business.experience')}
            </a>
          </NavigationItem>
        </NavigationItems>
        <BusinessNavigationDropdown
          i18n={props.i18n}
          t={props.t}
          tReady={props.tReady}
        />
      </NavigationDrawerFlex>
    )}
    <RouteLink href={INDEX.route} passHref>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
      <a>
        <Button white outline>
          {props.t('navigation.business.backToHome')}
        </Button>
      </a>
    </RouteLink>
  </>
);

export default BusinessNavigation;
