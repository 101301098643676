import createReactContext from 'create-react-context';

import { Session } from 'types';

export const defaults = {
  id: '',
  user: null,
};

const SessionContext = createReactContext<Session>(defaults);
const { Consumer, Provider } = SessionContext;

Consumer.displayName = 'SessionConsumer';
Provider.displayName = 'SessionProvider';

export { Provider };
export default Consumer;
