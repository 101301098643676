import React from 'react';
import Button from '@sizeid/commons/components/Button';
import { Icon } from '@sizeid/commons/components/SvgImage';
import { ACCOUNT_INDEX } from '@sizeid/shared/constants/routes';
import { WithTranslation } from 'next-i18next';

import RouteLink from 'components/RouteLink';
import Session from 'components/Session';

import { SIGN_BUTTON_ID } from './constants';

const SignButton: React.FC<WithTranslation> = props => (
  <RouteLink href={ACCOUNT_INDEX.route} passHref>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
    <a>
      <Button outline white>
        <Icon image="sizeid-label-inclined" size="1.5em" />
        <Session>
          {session =>
            session && session.user ? (
              <span id={SIGN_BUTTON_ID}>{session.user.profile_name}</span>
            ) : (
              props.t('navigation.account')
            )
          }
        </Session>
      </Button>
    </a>
  </RouteLink>
);

export default SignButton;
