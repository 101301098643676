import React from 'react';
import Head from 'next/head';

import { BASE_TITLE } from 'constants/index';

import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import { PageProps } from './types';

const Page: React.FC<PageProps> = props => {
  const title = props.title ? `${props.title} | ${BASE_TITLE}` : BASE_TITLE;
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="og:title" content={BASE_TITLE} />
      </Head>
      <Header
        withScroll={props.scrollingHeader}
        noHeader={props.noHeader}
        hideMenu={props.hideMenu}
      />
      <Content
        bottomPadding={!props.footer}
        noHeader={props.noHeader}
        noPadding={props.noPadding}
      >
        {props.children}
      </Content>
      {props.footer && <Footer />}
    </>
  );
};

export { default as Section } from './Section';
export { default as Wrapper } from './Wrapper';

export default Page;
