// Business
export const WHERE_TO_USE = 'where-to-use';
export const BENEFITS = 'benefits';
export const EXPERIENCE = 'experience';
export const REFERENCE = 'reference';
export const PRICING = 'pricing';
export const ADVISOR = 'advisor';
export const COMPLEX_TECHNOLOGIES = 'complex-technologies';
export const STATISTICS = 'statistics';

// Docs
export const PLATFORMS = 'platforms';
export const INTEGRATION_PRODUCTS = 'integration-platforms';
