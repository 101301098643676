import React from 'react';

import { ModalProps } from 'types';

import Modal from './Modal';

export const renderModal = (
  modalContent: JSX.Element,
  props: ModalProps,
): undefined | JSX.Element =>
  props.modalVisible ? (
    <Modal onCloseClick={props.hideModal}>{modalContent}</Modal>
  ) : undefined;

export { default as ModalRoot } from './ModalRoot';
export { default as withModal } from './withModal';
