import styled from 'styled-components';
import { key } from 'styled-theme';

export default styled.button`
  display: flex !important;
  align-items: center;
  background: none;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;

  .flag {
    border: 1px solid ${key('colors.black.default')};
    border-radius: 100%;
    margin-right: 15px;
  }
`;
