import { merge } from 'lodash';
import { BUSINESS_INDEX } from '@sizeid/shared/constants/routes';

import mutableDefaultTheme from './default';
import { DefaultThemeType } from './types';

const defaultTheme = merge({}, mutableDefaultTheme);

const businessTheme: DefaultThemeType = merge(defaultTheme, {
  backgrounds: {
    header: defaultTheme.colors.SIDB.default,
    graySection: defaultTheme.colors.SIDB.light[50],
    grayWave: 'sidbWave',
  },
  navigation: {
    type: 'business',
    anchorMargin: 'top',
    flexDirection: 'row',
  },
  logo: {
    image: 'sizeid-for-business-colored-inverse',
    width: {
      normal: 'auto',
      big: 'auto',
    },
    height: {
      ...defaultTheme.logo.height,
    },
    href: {
      page: BUSINESS_INDEX.page,
      route: BUSINESS_INDEX.route,
    },
  },
});

export default businessTheme;
