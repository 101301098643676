import styled from 'styled-components';
import { key } from 'styled-theme';
import { elementSize } from '@sizeid/commons/utils/styles';

const arrowSize = 10;
const arrowOffset = 20;

interface Props {
  visible: boolean;
}

export default styled.ul.attrs((props: Props) => ({
  style: { display: props.visible ? 'block' : 'none' },
}))<Props>`
  list-style: none;
  padding: 10px 0;
  border-radius: ${key('sizes.borderRadius')};
  background: ${key('colors.white')};
  box-shadow: ${key('outlines.shadow.default')};
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 99;
  margin-right: -${arrowSize / 2 + arrowOffset}px;

  &:after {
    ${elementSize(0)};
    content: '';
    bottom: 100%;
    right: ${arrowOffset}px;
    border: solid transparent;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: ${key('colors.white')};
    border-width: ${arrowSize}px;
    margin-left: -${arrowSize}px;
  }
`;
