import { GlobalThemeType } from '@sizeid/commons/themes/types';

import {
  BUSINESS_THEME,
  INTEGRATION_THEME,
  DEFAULT_THEME,
  Themes,
} from 'constants/index';
import defaultTheme from 'themes/default';
import businessTheme from 'themes/business';
import integrationTheme from 'themes/integration';

const themes: Themes = {
  [BUSINESS_THEME]: businessTheme,
  [DEFAULT_THEME]: defaultTheme,
  [INTEGRATION_THEME]: integrationTheme,
};

export default (theme: keyof typeof themes = DEFAULT_THEME): GlobalThemeType =>
  themes[theme] || themes[DEFAULT_THEME];
