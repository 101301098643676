import styled from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

export default styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;

  ${media.lessThan('sm')`
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 0;

    > div {
      flex: 0 0 100%;
      order: 1;
      margin-bottom: -20px;
    }

    > svg {
      order: 2;
      width: 90px;
    }

    > a {
      order: 3;
      margin-left: 30px;

      svg {
        width: 120px;
      }
    }
  `};
`;
