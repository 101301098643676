import React, { ComponentType } from 'react';

import { Session } from 'types';
import { getDisplayName } from 'utils/components';

import SessionConsumer from './Session';

const withSession = <T extends { session: Session }>(
  Component: ComponentType<T>,
): ComponentType<Omit<T, 'session'>> => {
  const ComponentWithSession: React.FC<Omit<T, 'session'>> = props => (
    <SessionConsumer>
      {session => {
        const joinedProps = { ...props, session } as T;
        return <Component {...joinedProps} session={session} />;
      }}
    </SessionConsumer>
  );

  ComponentWithSession.displayName = `WithSession(${getDisplayName(
    Component as ComponentType,
  )})`;

  return ComponentWithSession;
};

export default withSession;
