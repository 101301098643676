import React, { PureComponent } from 'react';
import withClickOutside from 'react-click-outside';

import DropdownButton from './styled/DropdownButton';
import DropdownContainer from './styled/DropdownContainer';
import DropdownItems from './styled/DropdownItems';
import { DropdownProps, DropdownState } from './types';

class Dropdown extends PureComponent<DropdownProps, DropdownState> {
  public state = { visible: false };

  public handleClickOutside(): void {
    if (this.state.visible) {
      this.setState({ visible: false });
    }
  }

  public toggleDropdownVisible: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ): void => {
    event.preventDefault();
    this.setState(prevState => ({ visible: !prevState.visible }));
  };

  public render(): JSX.Element {
    return (
      <DropdownContainer>
        <DropdownButton
          onClick={this.toggleDropdownVisible}
          labelStyle={this.props.labelStyle}
        >
          {this.props.label}
        </DropdownButton>
        <DropdownItems visible={this.state.visible}>
          {typeof this.props.children === 'function'
            ? this.props.children(this.toggleDropdownVisible)
            : this.props.children}
        </DropdownItems>
      </DropdownContainer>
    );
  }
}

export { default as DropdownItem } from './styled/DropdownItem';

export default withClickOutside(Dropdown);
