import {
  css,
  FlattenInterpolation,
  ThemedStyledProps,
} from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

import { ThemedSectionProps } from '../types';

export const centeredHeader =
  'text-align: center !important; margin-left: auto !important; margin-right: auto !important;';

const jumbotron = (
  props: ThemedSectionProps,
): FlattenInterpolation<ThemedStyledProps<unknown, unknown>> => {
  if (!props.headerColor && !props.sidbColor) return css``;
  return css`
    h1,
    p {
      color: white !important;
    }

    h1 {
      margin: 20px 0;
      max-width: 600px;
    }

    p {
      margin: 0 0 30px;
      max-width: 550px;
    }

    ${media.moreThan('smPlus')`
      h1, p {
        text-align: left;
      }

      p {
        font-size: 20px;
      }
    `};

    ${media.lessThan('sm')`
      p {
        margin-left: auto;
        margin-right: auto;
      }
    `};
  `;
};

export default jumbotron;
