import React from 'react';

import SectionContainer from './styled/SectionContainer';
import { SectionProps } from './types';

const Section: React.FC<SectionProps> = props => (
  <SectionContainer {...props}>{props.children}</SectionContainer>
);

export default Section;
