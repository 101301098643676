import React, { PureComponent } from 'react';
import withClickOutside from 'react-click-outside';
import { withTheme } from 'styled-components';

import { BUSINESS_THEME, INTEGRATION_THEME } from 'constants/index';
import { withTranslation } from 'lib/i18n';

import NavigationDrawer from './styled/NavigationDrawer';
import NavigationDrawerContainer from './styled/NavigationDrawerContainer';
import NavigationHamburger from './NavigationHamburger';
import NavigationContainer from './styled/NavigationContainer';
import DefaultNavigation from './DefaultNavigation';
import BusinessNavigation from './BusinessNavigation';
import DocsNavigation from './DocsNavigation';
import { NavigationProps, NavigationState } from './types';

class Navigation extends PureComponent<NavigationProps, NavigationState> {
  public state = { visible: false };

  public handleClickOutside(): void {
    if (this.state.visible) {
      this.setState({ visible: false });
    }
  }

  public toggleNavigationVisible: React.MouseEventHandler = event => {
    event.preventDefault();
    this.setState(prevState => ({ visible: !prevState.visible }));
  };

  public renderNavigation = (): JSX.Element => {
    switch (this.props.theme.navigation.type) {
      case BUSINESS_THEME:
        return (
          <BusinessNavigation
            i18n={this.props.i18n}
            t={this.props.t}
            tReady={this.props.tReady}
            hideMenu={this.props.hideMenu}
          />
        );
      case INTEGRATION_THEME:
        return (
          <DocsNavigation
            i18n={this.props.i18n}
            t={this.props.t}
            tReady={this.props.tReady}
            hideMenu={this.props.hideMenu}
          />
        );
      default:
        return (
          <DefaultNavigation
            i18n={this.props.i18n}
            t={this.props.t}
            tReady={this.props.tReady}
          />
        );
    }
  };

  public render(): JSX.Element {
    return (
      <NavigationContainer>
        <NavigationDrawerContainer visible={this.state.visible}>
          <NavigationHamburger
            onClick={this.toggleNavigationVisible}
            visible={this.state.visible}
          />
          <NavigationDrawer visible={this.state.visible}>
            {this.renderNavigation()}
          </NavigationDrawer>
        </NavigationDrawerContainer>
      </NavigationContainer>
    );
  }
}

export default withTheme(
  withTranslation('common')(withClickOutside(Navigation)),
);
