import styled from 'styled-components';

export default styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  overflow-x: hidden;
  overflow-y: auto;

  > div {
    position: relative;
    padding: 5% 0;
    height: 100%;
    max-width: 90%;
  }
`;
