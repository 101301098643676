import styled from 'styled-components';
import { key } from 'styled-theme';
import { media } from '@sizeid/commons/utils/styles';

import { navigationDrawerWidth } from './NavigationDrawer';

interface Props {
  visible?: boolean;
}

export default styled.div.attrs((props: Props) => ({
  style: { width: props.visible ? navigationDrawerWidth : 0 },
}))<Props>`
  height: 120%;
  transition: width 0.33s ease-in-out;
  will-change: width;
  box-shadow: ${key('outlines.shadow.default')};
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;

  ${media.moreThan('md')`
    width: auto !important;
    height: auto;
    box-shadow: none;
    overflow: initial;
    position: relative;
  `};
`;
