import { parse } from 'url';
import { IncomingMessage, ServerResponse } from 'http';

import { ACCOUNT_INDEX, SIGN_INDEX } from '@sizeid/shared/constants/routes';
import { NextRouter } from 'next/router';

import { Router } from 'lib/i18n';

const RETURN_URL = 'returnUrl';

export const afterSignRedirect = (): void => {
  if (Router.query[RETURN_URL]) {
    window.location.href = decodeURIComponent(
      Router.query[RETURN_URL] as string,
    );
    return;
  }
  window.location.href = ACCOUNT_INDEX.route;
};

export const authRedirect = (
  req: IncomingMessage | null | undefined,
  res: ServerResponse | null | undefined,
  locale: string,
): void => {
  if (req && res) {
    const url = parse(`${req.url}`, true);
    res.writeHead(303, {
      Location: `/${locale}${SIGN_INDEX.route}${
        url.path ? `?${RETURN_URL}=${encodeURIComponent(url.path)}` : ''
      }`,
    });
    res.end();
  } else {
    Router.push(SIGN_INDEX.route);
  }
};

export const facebookLogin = (url: NextRouter): string => {
  const { query } = url;
  const allowedQuery = [RETURN_URL, 'source'];
  const facebookQuery = allowedQuery
    .map(name => {
      const value = query[name];
      if (value) {
        return `${encodeURIComponent(name)}=${encodeURIComponent(`${value}`)}`;
      }
      return null;
    })
    .filter(a => a)
    .join('&');

  return `${FACEBOOK_AUTH}${facebookQuery ? `?${facebookQuery}` : ''}`;
};
