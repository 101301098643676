import React from 'react';
import { WithTranslation } from 'next-i18next';

import AdvisorTrigger from 'components/AdvisorTrigger';
import {
  ADVISOR_MODE_SELECT_SIZE_CHART,
  RECOMMENDATION_MODE_PROPERTIES,
} from 'components/AdvisorTrigger/types';

import NavigationItemContainer from '../NavigationItem/styled/NavigationItemContainer';

const RecommendSize: React.FC<WithTranslation> = props => (
  <NavigationItemContainer>
    <AdvisorTrigger
      advisorMode={ADVISOR_MODE_SELECT_SIZE_CHART}
      recommendationMode={RECOMMENDATION_MODE_PROPERTIES}
    >
      {props.t('navigation.advisor')}
    </AdvisorTrigger>
  </NavigationItemContainer>
);

export default RecommendSize;
