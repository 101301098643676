import React from 'react';

import ContentContainer from './styled/ContentContainer';
import { ContentProps } from './types';

const Content: React.FC<ContentProps> = props => (
  <ContentContainer
    bottomPadding={props.bottomPadding}
    noPadding={props.noPadding}
    noHeader={props.noHeader}
  >
    {props.children || null}
  </ContentContainer>
);

export default Content;
