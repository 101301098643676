import { css, FlattenInterpolation, ThemeProps } from 'styled-components';
import { ifProp } from 'styled-tools';
import { media } from '@sizeid/commons/utils/styles';
import { CSSInterpolation } from '@sizeid/commons/types';

import grayWave from '../images/wave_gray.svg';
import sidbWave from '../images/wave_sidb_gray.svg';
import whiteWave from '../images/wave_white.svg';
import { ThemedSectionProps } from '../types';

const waves = { grayWave, sidbWave, whiteWave };

export const dimensions = {
  mobile: 17,
  tablet: 45,
  desktop: 64,
  large: 84,
};

const baseStyle = (): CSSInterpolation => css`
  content: '';
  display: block;
  height: ${dimensions.mobile}px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  left: 0;
  right: 0;

  ${media.moreThan('sm')`
    height: ${dimensions.tablet}px;
  `};

  ${media.moreThan('md')`
    height: ${dimensions.desktop}px;
  `};

  ${media.moreThan('lg')`
    height: ${dimensions.large}px;
  `};
`;

const waveBackground = (props: ThemedSectionProps): string =>
  props.gray
    ? waves[props.theme.backgrounds.grayWave as keyof typeof waves]
    : waves.whiteWave;

const wave = (
  props: ThemedSectionProps,
): FlattenInterpolation<ThemeProps<unknown>> => {
  let topWave: FlattenInterpolation<ThemeProps<unknown>> = css``;
  let bottomWave: FlattenInterpolation<ThemeProps<unknown>> = css``;

  if (props.topWave) {
    topWave = css`
      &:before {
        ${baseStyle};
        z-index: 0;
        background-image: url('${waveBackground}');
        top: -${dimensions.mobile}px;
        ${ifProp('flipTopWave', 'transform: scaleX(-1);', '')};

        ${media.moreThan('sm')`
          top: -${dimensions.tablet}px;
        `};

        ${media.moreThan('md')`
          top: -${dimensions.desktop}px;
        `};

        ${media.moreThan('lg')`
          top: -${dimensions.large}px;
        `};
      }
    `;
  }

  if (props.bottomWave) {
    bottomWave = css`
      &:after {
        ${baseStyle};
        z-index: 1;
        background-image: url('${waveBackground}');
        transform: rotate(180deg)
          ${ifProp('flipBottomWave', ' scaleX(-1);', '')};
        bottom: -${dimensions.mobile}px;

        ${media.moreThan('sm')`
          bottom: -${dimensions.tablet}px;
        `};

        ${media.moreThan('md')`
          bottom: -${dimensions.desktop}px;
        `};

        ${media.moreThan('lg')`
          bottom: -${dimensions.large}px;
        `};
      }

      & + & {
        padding-top: ${props =>
          props.theme.sizes.pageContent.mobileBottomPadding +
          dimensions.mobile / 3}px;

        ${media.moreThan('sm')`
          padding-top: ${props =>
            props.theme.sizes.pageContent.bottomPadding +
            dimensions.tablet / 3}px;
        `};

        ${media.moreThan('md')`
          padding-top: ${props =>
            props.theme.sizes.pageContent.bottomPadding +
            dimensions.desktop / 3}px;
        `};

        ${media.moreThan('lg')`
          padding-top: ${props =>
            props.theme.sizes.pageContent.bottomPadding +
            dimensions.large / 3}px;
        `};
      }
    `;
  }

  if (props.footerWave) {
    bottomWave = css`
      > div {
        z-index: 1;
      }

      &:before,
      &:after {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        z-index: 0;
      }

      &:before {
        height: ${dimensions.large}px;
        bottom: ${dimensions.large * 2}px;
        background: url('${grayWave}') no-repeat;
        background-position: bottom center;
        background-size: 110% auto;
        transform: scaleX(-1);
      }

      &:after {
        height: ${dimensions.large * 2}px;
        bottom: 0;
        background: #f5f5f5;
      }
    `;
  }

  return css`
    ${topWave};
    ${bottomWave};
  `;
};

export default wave;
