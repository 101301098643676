import React from 'react';

import NavigationHamburgerContainer from './styled/NavigationHamburgerContainer';

const NavigationHamburger: React.FC<{
  onClick: React.MouseEventHandler;
  visible: boolean;
}> = ({ onClick, visible }) => (
  <NavigationHamburgerContainer onClick={onClick} visible={visible}>
    <button>
      <span />
      <span />
      <span />
      <span />
    </button>
  </NavigationHamburgerContainer>
);

export default NavigationHamburger;
