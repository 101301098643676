import styled from 'styled-components';
import { media } from '@sizeid/commons/utils/styles';

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.lessThan('sm')`
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 30px;

    svg + span {
      display: none;
    }

    .LanguageSelect-map {
      display: none;
    }

    .LanguageSelect-btn {
      margin: 0 0 0 5px;
    }
  `};
`;
