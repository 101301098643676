import styled from 'styled-components';
import { key } from 'styled-theme';

export default styled.div`
  position: relative;
  padding: 40px;
  border-radius: ${key('sizes.borderRadius')};
  box-shadow: ${key('outlines.shadow.default')};
  background: ${key('backgrounds.primary')};
  color: ${key('text.primary')};
  margin-bottom: 5%;
`;
