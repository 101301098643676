import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';

import { BODY_ID } from 'constants/index';

import ModalContent from './ModalContent';
import ModalOverlay from './styled/ModalOverlay';
import { MODAL_ROOT_ID, MODAL_CONTAINER_CLASS } from './constants';
import { ModalProps } from './types';

class Modal extends PureComponent<ModalProps> {
  public body: HTMLElement | null | undefined;

  public container: HTMLElement;

  public modalRoot: HTMLElement | null | undefined;

  public constructor(props: ModalProps) {
    super(props);

    this.body = document.getElementById(BODY_ID);
    this.container = this.createContainer();
    this.modalRoot = document.getElementById(MODAL_ROOT_ID);
  }

  public componentDidMount(): void {
    if (this.modalRoot) {
      this.modalRoot.appendChild(this.container);
      setTimeout(() => {
        this.container.style.opacity = '1';
        if (this.body) {
          this.body.style.overflow = 'hidden';
        }
      }, 100);
    }
  }

  public componentWillUnmount(): void {
    if (this.modalRoot) {
      this.modalRoot.removeChild(this.container);
      if (this.body) {
        this.body.removeAttribute('style');
      }
    }
  }

  public createContainer = (): HTMLDivElement => {
    const container = document.createElement('div');
    container.className = MODAL_CONTAINER_CLASS;
    return container;
  };

  public renderModal = (): JSX.Element => (
    <ModalOverlay>
      <div>
        <ModalContent onClose={this.props.onCloseClick}>
          {this.props.children}
        </ModalContent>
      </div>
    </ModalOverlay>
  );

  public render(): JSX.Element {
    if (!this.modalRoot) return <></>;
    return createPortal(this.renderModal(), this.container);
  }
}

export default Modal;
