import styled from 'styled-components';
import { prop } from 'styled-tools';
import { key } from 'styled-theme';
import { elementSize } from '@sizeid/commons/utils/styles';

export default styled.button<{ labelStyle?: string }>`
  font-family: 'Brix Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  color: ${prop('color', key('colors.white'))};
  padding-right: 18px;

  &:after {
    ${elementSize(0)};
    content: '';
    right: 0;
    top: 50%;
    position: absolute;
    pointer-events: none;
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: ${prop('color', key('colors.white'))};
    border-width: 5px;
    margin-left: -5px;
    margin-top: -1.5px;
  }

  ${prop('labelStyle', '')};
`;
